.cookies-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.cookies-overlay {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 1rem;
  max-height: 80vh;
  overflow-y: auto;
}

.cookies-text {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.cookies-links {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.cookies-links a {
  color: #fff;
  margin: 0 1rem;
}

button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background-color: #000;
  color: #fff;
}

@media (min-width: 768px) {
  .cookies-overlay {
    max-width: 80%;
    margin: 0 auto;
    border-radius: 0.5rem;
  }
}