/* General reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Typography and theme */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #1e1e1e;
  color: #fff;
}

h2 {
  text-align: center;
  width: 100%;
}


/* App container */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

/* App header */
.App-header {
  padding: 2rem 0;
  background-color: #1e1e1e;
  border-bottom: 1px solid #3f3f3f;
  color: #fff;
}
h2 {
  margin:2rem 0;
}

button {
  margin: 1rem;
}
/* Section spacing */
section {
  margin-bottom: 3rem;
  border-bottom: 1px solid #3f3f3f;
  padding-bottom: 3rem;
}

/* Last section border fix */
#founders {
  border-bottom: none;
}

/* Meetup date section */
#meetup-date {
  text-align: center;
}

.left-text {
  text-align: left;
}


/* Get tickets button */
button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #1e1e1e;
  background-color: #4ecdc4;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #3dbda7;
}

/* Location section */
#location {
  text-align: center;
}

/* Map */
iframe {
  width: 100%;
  max-width: 600px;
  height: 450px;
  margin-top: 1rem;
  border: none;
}

/* Speakers and founders sections */

#speakers {
  justify-content: center;
}

#speakers ul {
  display: flex;
  justify-content: space-between;
}

.organizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.organizer h3 {
  text-align: center;
  width: 180px;
}

.logo {
  width: 100%;
  padding-bottom: 2em;
}

.promo-logo {
  text-align: center;
  margin: auto;
  width: 40%;
  padding-bottom: 2em;
}

#sponsors {
  text-align: center;
}

.avatar-placeholder img {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
  background-color: #3f3f3f;
  border-radius: 50%;
  border: 2px solid #4ecdc4;
}

/* Social media links */
.social-links {
  display: flex;
  gap: 0.5rem;
}

.social-links a {
  color: #4ecdc4;
  text-decoration: none;
}

/* Footer */
footer {
  text-align: center;
  margin-top: 3rem;
}

/* Responsive design */
@media (min-width: 768px) {
  .speaker,
  .organizer {
    flex-direction: row;
    justify-content: space-between;
  }

  .avatar-placeholder {
    margin-right: 1rem;
  }
}

.speaker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 240px;
  margin-bottom: 2rem;
}

.speaker-content-wrapper {
  position: relative;
  display: inline-block;
}

.speaker-content {
  max-height: fit-content;
}

.read-more {
  display: block;
  margin-top: 0.5em;
  background-color: transparent;
  border: none;
  color: #007BFF; /* Or any other color you prefer */
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}


.avatar-placeholder {
  width: 100%;
  max-width: 150px;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.avatar-placeholder img {
  width: 100%;
  height: auto;
}

.speaker h3 {
  margin-bottom: 0.5rem;
}

.speaker p {
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  #speakers ul {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
}
